import * as React from 'react'
import classNames from 'classnames/bind'
import * as styles from './Intro.module.css'

const cx = classNames.bind(styles)

const Intro = ({title, children, style}) => {

return (
    <div className={cx("base")} style={style}>
        <h1>{title}</h1>
        <p>{children}</p>
    </div>
)}

export default Intro;