import * as React from 'react'
import classNames from 'classnames/bind'
import * as styles from './Card.module.css'

const cx = classNames.bind(styles)

const Card = ({ active, className, icon, onClick, style, children }) => {
    return (
        <div className={cx("base", className, {clickable: onClick, active})} onClick={onClick}>
            {icon}
            <div className={cx("content")}>
                {children}
            </div>
        </div>
    )
}

export default Card;