import * as React from 'react'
import classNames from 'classnames/bind'
import * as styles from './Hero.module.css'
import Navbar from '../Navbar/Navbar'
import logo from '../../images/Logo.png'

const cx = classNames.bind(styles)

/* 
    This Hero has two versions:
    * standard (no props) –> used on index page
    * minimized (mini) –> used onother pages
*/

const Hero = ({mini}) => {

return (
    <div className={cx("base", {mini})}>
        <img src={logo} />
        <Navbar main={!mini} mini />
    </div>
)}

export default Hero;