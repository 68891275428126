import * as React from 'react'
import classNames from 'classnames/bind'
import * as styles from './ContactForm.module.css'
import Container from "../Container/Container"
import Button from "../Button/Button"

const cx = classNames.bind(styles)

const ContactForm = props => {

    return (
        <div className={cx("base")}>
            <Container flex>
                <div className={cx("text")}>
                    <h1>Huisbezoek &#38; Behandelingen</h1>
                    <p>Stuur een bericht en ik neem zo snel mogelijk contact met je op. </p>
                </div>

                <form action="https://submit-form.com/0Rpa53qQ">
                    <fieldset>
                        <label for="name">Naam*</label>
                        <input type="text" id="name" name="name" placeholder="Name" required />
                    </fieldset>

                    <fieldset>
                        <label for="name">Telefoon</label>
                        <input type="tel" id="tel" name="tel" placeholder="Telefoon" required />
                    </fieldset>

                    <fieldset>
                        <label for="email">E-mail</label>
                        <input type="email" id="email" name="email" placeholder="E-mail" required="" />
                    </fieldset>

                    <fieldset>
                        <label for="message">Bericht</label>
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Bericht"
                        ></textarea>
                    </fieldset>
                    <Button navy type="submit">Opsturen</Button>
                </form>


            </Container>


        </div>

    )
}

export default ContactForm;