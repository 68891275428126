import * as React from "react";
import classNames from "classnames/bind";
import * as styles from "./Footer.module.css";
import Container from "../Container/Container";
import Navbar from "../Navbar/Navbar";

const cx = classNames.bind(styles);

const Footer = (props) => (
  <div className={cx("base")}>
    <Container flex>
      <div className={cx("contact")}>
        <strong>Contact</strong>
        <p>
          www.yirpaministry.com <br />
          <a href="mailto:info@yirpaministry.com">
            info@yirpaministry.com
          </a>{" "}
          <br />
          Tel: 0628021544 <br />
          Adres: Weerdingerkanaal Zuidzijde 129,
          <br /> 7831 AH, Nieuw-Weerdinge <br />
          Kvk: 74228315 <br />
          Bankrekening: NL13KNAB0258762012 <br />
        </p>
      </div>
      <div className={cx("nav")}>
        <p>
          Yirpa is een Christelijke praktijk die natuurlijke behandelingen
          gebruikt om herstel in het lichaam te bevorderen en tot stand te
          brengen.
        </p>
        <Navbar />
      </div>
    </Container>
  </div>
);

export default Footer;
