import React from "react";
import * as styles from "./styles/pricing.module.css";
import classnames from "classnames/bind";
import Hero from "../components/HeroSection/Hero";
import Intro from "../components/IntroSection/Intro";
import Card from "../components/Card/Card";
import Container from "../components/Container/Container";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";
import ContactForm from "../components/ContactForm/ContactForm";

const cx = classnames.bind(styles);

const Pricing = () => {
  return (
    <main className={cx("base")}>
      <title>Yirpa Ministries | Tarieven</title>
      <Hero mini />
      <Intro title={"Tarieven"} style={{ paddingBottom: "7rem" }}>
        Yirpa is een Christelijke praktijk die natuurlijke behandelingen
        gebruikt om herstel in het lichaam te bevorderen en tot stand te
        brengen.
      </Intro>

      {true && (
        <div className={cx("prices", "hydro")}>
          <Container>
            <Container flex className={cx("flexBox")}>
              <Card icon={<span>shower</span>} className={cx("card")}>
                <h3>Waterbehandeling</h3>
                <small>Hydrotherapie</small>

                <div className={cx("amount")}>
                  <strong>50</strong> euro
                </div>
              </Card>
            </Container>
          </Container>
        </div>
      )}

      {true && (
        <div className={cx("prices", "counselling")}>
          <Container>
            <Container flex className={cx("flexBox")}>
              <Card icon={<span>emoji_people</span>} className={cx("card")}>
                <h3>Eerste consult en behandelplan</h3>
                <small>Lifestyle counselling</small>

                <div className={cx("amount")}>
                  <strong>50</strong> euro
                </div>
              </Card>

              <Card
                icon={<span>sentiment_very_satisfied</span>}
                className={cx("card")}
              >
                <h3>Vervolgconsulten</h3>
                <small>Lifestyle counselling</small>

                <div className={cx("amount")}>
                  <strong>40</strong> euro
                </div>
              </Card>
            </Container>
          </Container>
        </div>
      )}

      {true && (
        <div className={cx("prices", "detox")}>
          <Container>
            <Container flex className={cx("flexBox")}>
              <Card icon={<span>emoji_nature</span>} className={cx("card")}>
                <h3>5-daagse detox</h3>
                <small>Detox programma</small>

                <div className={cx("amount")}>
                  <strong>500</strong> euro
                </div>
              </Card>

              <Card
                icon={<span>sentiment_very_satisfied</span>}
                className={cx("card")}
              >
                <h3>10-daagse detox</h3>
                <small>Detox programma</small>

                <div className={cx("amount")}>
                  <strong>1200</strong> euro
                </div>
              </Card>

              <Card icon={<span>emoji_nature</span>} className={cx("card")}>
                <h3>15-daagse detox</h3>
                <small>Detox programma</small>

                <div className={cx("amount")}>
                  <strong>1700</strong> euro
                </div>
              </Card>
            </Container>
          </Container>
        </div>
      )}

      <ContactForm />

      <Footer />
    </main>
  );
};

export default Pricing;
