import * as React from "react";
import classNames from "classnames/bind";
import * as styles from "./Navbar.module.css";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const cx = classNames.bind(styles);

const Navbar = ({ main, mini }) => {
  return (
    <div className={cx("base", { main, mini })}>
      <Link to="/">Home</Link>
      <AnchorLink to="/#whoAmI">Wie ben ik?</AnchorLink>
      <Link to="/#whatIDo">Wat doe ik?</Link>
      <Link to="/therapieen">Therapieën</Link>
      <Link to="/tarieven">Tarieven</Link>
      <Link to="/contact">Contact</Link>
    </div>
  );
};

export default Navbar;
