import * as React from 'react'
import classNames from 'classnames/bind'
import * as styles from './Button.module.css'

const cx = classNames.bind(styles)

const Button = ({block, center, navy, style, type, children}) => {

return (
    <button type={type} className={cx("base", {block, navy})} style={style}>{children}</button>

)}

export default Button;