import * as React from "react";
import classNames from "classnames/bind";
import * as styles from "./Container.module.css";

const cx = classNames.bind(styles);

const Container = ({ flex, style, children, className }) => {
  return (
    <div className={cx("base", className, { flex })} style={style}>
      {children}
    </div>
  );
};

export default Container;
